<template>
  <div class="home">
    <div class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="home-banner scrollitem">
            <el-carousel height="6.9rem" ref="carousel" :autoplay="false">
              
                <el-carousel-item>
                  <router-link to="/product">
                  <div class="banner-t">
                    <img src="../assets/imgs/banner_text.png" alt=""><br/>
                    <img src="../assets/imgs/banner_btn.png" alt="">
                    <!-- <h3 class="down-animate animate__animated animate__fadeInDown">九洲软件</h3>
                    <h4>“云计算技术+大数据+场景应用”解决方案提供商</h4>
                    <p class="up-animate">深耕各行业细分场景解决方案，赋能客户数字化转型</p>
                    <button class="up-animate">了解更多</button> -->
                  </div>
                  <div id="lottie_box" class="animation" style="position:absolute;right:-1.2rem;width:10rem;"></div>
                </router-link>
                </el-carousel-item>
              
              
                <el-carousel-item>
                  <router-link to="/product">
                    <div class="banner-t">
                      <img src="../assets/imgs/banner2_text.png" alt=""><br/>
                      <img src="../assets/imgs/banner_btn.png" alt="">
                      <!-- <h3 class="down-animate">大数据平台</h3>
                      <h4 class="animate__animated animate__bounce">针对细分领域应用场景，提供大数据全套解决方案</h4>
                      <p class="up-animate">数据采集、分析、展现全流程智能化处理，应用多个高新领域，<br/>备受用户青睐</p> -->
                      <!-- <button class="up-animate">了解更多</button> -->
                    </div>
                    <img class="home-imgs" style="width:4.5rem;top:15%;right:-1.5rem" src="../assets/imgs/banner2.png" alt="" srcset="">
                  </router-link>
                </el-carousel-item>
              
              
                <el-carousel-item>
                  <router-link to="/product">
                    <div class="banner-t">
                      <img src="../assets/imgs/banner3_text.png" alt=""><br/>
                      <img src="../assets/imgs/banner_btn.png" alt="">
                      <!-- <h3 class="down-animate">智能化设备</h3>
                      <h4>系统结构更加明确，粒度更细</h4>
                      <p class="up-animate">灵敏准确的感知功能、正确的思维与判断功能、行之有效的执行功能</p>
                      <button class="up-animate">了解更多</button> -->
                    </div>
                    <img class="home-imgs" style="top:25%;right:-2.4rem;" src="../assets/imgs/banner3.png" alt="" srcset="">
                  </router-link>
                </el-carousel-item>
              
              
                <el-carousel-item>
                  <router-link to="/product">
                    <div class="banner-t">
                      <img src="../assets/imgs/banner4_text.png" alt=""><br/>
                      <img src="../assets/imgs/banner_btn.png" alt="">
                        <!-- <h3 class="down-animate">Saas云服务</h3>
                      <h4>高效帮助企业搭建Saas系统，助力业务发展</h4>
                      <p class="up-animate">满足复杂需求、支持个性化定制，快速实现一体化管理</p>
                      <button class="up-animate">了解更多</button> -->
                    </div>
                    <img class="home-imgs" src="../assets/imgs/banner4.png" alt="" srcset=""  style="top:3rem;right:-2rem;">
                  </router-link>
                </el-carousel-item>
            </el-carousel>
            <div class="home-banner-tab">
            <el-carousel height="1.6rem" direction="vertical" ref="carousel" :autoplay="false" indicator-position="none">
              <el-carousel-item v-for="(item1,index1) in bannerTabs" :key="index1">
                <div class="home-banner-tab-item1">
                  <div class="tab-icon" v-for="(i,k) in item1.tabs" :key="k">
                    <img :src="i.icon" alt="" srcset="" class="hover-animate">
                    <p>{{i.text}}</p>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        </div>
        <div class="swiper-slide">
          <!-- 多元整合 -->
          <Collec class="scrollitem" style="margin-top:50px;">
            <template #diy_title>
              <div class="collec-title" style="margin:0 auto;height:1rem;">
              <h3><router-link to="/swipers">多元整合，满足各行业应用需求</router-link></h3>
              
            </div>
            </template>
          </Collec>
        </div>
        <div class="swiper-slide">
          <div class="home-transform scrollitem">
            <div class="collec-title" style="margin:0.2rem auto 0;">
              <h3>深耕各行业细分场景解决方案，赋能客户数字化转型</h3>
              
            </div>
            <div class="trans-content">
              <el-tabs tab-position="left">
                <el-tab-pane :label="item.za_title" v-for="(item,index) in solutionList" :key="index">
                  <h2 class="trans-title font20" style="padding-top:0.1rem"><a href="javascript:;">{{item.za_subhead}}</a></h2>
                  <div class="trans-describe">
                    <div class="trans-des-position">
                      <div class="desc">定位</div>
                      <div class="desc-text" style="font-size:14px;line-height:2em;">{{item.za_intro}}</div>
                    </div>
                    <div class="trans-des-plat">
                      <div class="desc">核心平台</div>
                      <div class="plat-b">
                        <a :href="_i.link!=''?_i.link:'javascript:;'" v-for="(_i,_j) in item.za_content"><button>{{_i.name}}</button></a>
                      </div>
                    </div>
                  </div>
                  <div class="trans-imgs">
                    <img :src="item.za_thumb" alt="" class="big-img" />
                  </div>
                  <div class="trans-b">
                    <button class="btn1" v-if="item.is_nav" @click="target(item)">了解更多</button>
                    <router-link to="/support"><button class="btn2">立即咨询</button></router-link>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <!-- 大数据新时代 -->
          <div class="home-bigdata scrollitem">
            <div class="collec-title" style="margin-top:0.5rem;height:1rem;">
              <h3>“大数据+智能”新时代</h3>
              <p class="collec-describe">
                九洲软件核心技术架构，由混合云（大数据库）作为底层框架，算法引擎实现数据处理超大图计算模型，千万定点、亿级边运算时间&lt;1秒。运行四维时空视界算法，实现千万级数据秒级运算能力。掌握并运用云环境下laaS、PaaS层基础技术，攻克PaaS层业务流转、消息控制、数据一致性等5项关键技术。实现组件快速聚合、业务柔性可定制。
              </p>
            </div>
            <div class="home-bigdata-content">
              <img src="../assets/imgs/img11.jpg" style="height:560px;width:1200px;" />
              <!-- <div id="lottie_box1" class="animation1" style="height:5rem;"></div> -->
              <div class="trans-b">
              <router-link to="/about"><button class="btn1">了解更多</button></router-link>
              <router-link to="/support"><button class="btn2">立即咨询</button></router-link>
            </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="home-dynamic scrollitem">
            <div class="dynamic-cont">
              <h3>公司动态</h3>
              <el-tabs tab-position="left" :before-leave="beforeTabLeave">
                <el-tab-pane label="新闻资讯">
                  <div class="dynamic-item" v-for="(item,index) in companyNews" :key="item.za_id" @click="hrefs(item.za_id,index)">
                    <a href="javascript:;"><img :src="item.za_thumb" alt=""></a>
                    <p>{{item.za_create_time}}</p>
                    <a href="javascript:;"><h3>{{item.za_title}}</h3></a>
                  </div>
                </el-tab-pane>
                <el-tab-pane>
                   <span slot="label"><a href="https://zhdj.jezetek.cc/" target="_blank">党建活动</a></span>
                </el-tab-pane>
                
                
                <el-tab-pane>
                  <span slot="label"><router-link to="/support">社会招聘</router-link></span>
                </el-tab-pane>
                <el-tab-pane>
                  <span slot="label"><router-link to="/news">查看更多</router-link></span>
                </el-tab-pane>
              </el-tabs>
            </div> 
          </div>
          
        </div>
        <div class="swiper-slide">
          <CompanyInfo style="height:5.6rem;"/>
        </div>
      </div>
      
    </div>
  </div>
</template>

<style lang="less" scoped>
@import url("../utils/public.less");
@import url("../utils/element.less");
  .swiper{
    // border:1px solid red;
    // padding-top:1rem;
    width:100%;
    height:calc(100vh);
    --swiper-theme-color:#db2c32;
    // text-align:center;
    // color:#fff;
    font-size:24px;

    .swiper-slide-active{
      height:auto !important;
    }
  }

  .el-carousel{
    width:1300px;
    margin:auto;

  }
  /deep/ .el-carousel__arrow--left{
    left:0 !important;
    i{
      color:#fff !important;
    }
  }
  /deep/ .el-carousel__arrow--right{
    right:0 !important;
      i{
        color:#fff !important;
      }
    }
</style>

<script>
import Collec from '@/components/Collec.vue';
import $request from '../utils/request.js';
import lottie from 'lottie-web';
import CompanyInfo from '@/components/CompanyInfo.vue';
import "animate.css";
export default {
  name:'swiper',
  data(){
    return{
      mySwiper:null,
      bannerTabs:[
        {
          pid:1,tabs:[
          {id:1,icon:require("../assets/imgs/list1.png"),text:"数据融合,聚焦价值"}, 
          {id: 2, icon: require("../assets/imgs/list2.png"), text: "数字实战,定制方案"}, 
          {id: 3, icon: require("../assets/imgs/list3.png"), text: "核心科技,自主可控"}, 
          {id: 4, icon: require("../assets/imgs/list4.png"), text: "安全灵活,数据生态"}
          ]
        }
      ],
      solutionList:null,
      newsList:null,
      companyNews:null
    }
  },
  components:{
    Collec,CompanyInfo
  },
  methods:{
    hrefs(ids,index){
      
      
      if(this.companyNews[index].za_jump_href){
        location.href = this.companyNews[index].za_jump_href
      }else{
        this.$router.push({path:"/newsinfo",query:{id:ids,type:1}})
      }
    },
    target(item){
      console.log(item)
      this.$router.push({path:'/solution',query:{page:item.za_id}})
    },
    targetTo(str){
      console.log(str)
      if(str == 'dang'){
        console.log('111')
      }else{
        console.log(str)
      }
    },
    beforeTabLeave(){
      return true;
    }
  },
  created(){
    this.$api.get($request.solutionHome).then(res=>{
      console.log(res)
      this.solutionList = res.data.data;
    })
    this.$api.get($request.newsHome).then(res=>{
      this.newsList = res.data.data;
      this.companyNews = res.data.data;
    })
  },
  mounted(){
    this.mySwiper = new Swiper(".swiper",{
      autoplay: false, //可选选项，自动滑动
      // init: true,
      mousewheel: true,
      slidesPerView:"auto",
      // updateOnWindowResize: true,
      // speed: 1200,
      // noSwiping: true,
      autoHeight: true, //高度随内容变化
      observer: true,
      observeParents: true,
      // slidesPerView:'auto',
      direction: 'vertical', // 垂直切换选项
      loop: false, // 循环模式选项
      // roundLengths: true, //防止文字模糊
      // threshold: 30,
    })

    lottie.loadAnimation({
      container:document.getElementById("lottie_box"),
      renderer:'svg',
      loop:true,
      autoplay:true,
      path:'/data.json'
    })

    lottie.loadAnimation({
      container:document.getElementById("lottie_box1"),
      renderer:'svg',
      loop:true,
      autoplay:true,
      path:'/data3.json'
    })
  }
}
</script>