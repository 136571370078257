const URLS  ={
    solutionHome:'/api/solution/home', //首页解决方案
    newsHome:'/api/news/home', //解决方案导航
    newsDetail:'/api/news/detail',  //解决方案页面内容
    solutionNav:'/api/solution/navigation',  //首页新闻
    solutionPage:'/api/solution/page',  //新闻中心
    newsCenter:'/api/news/center',   //公示公告
    notesCenter:'/api/notes/center',  //新闻详情
    notesDetail:'/api/notes/detail'   //公示公告详情
}


let urls = {}

if(process.env.NODE_ENV==="production"){
    if (process.env.VUE_APP_FLAG==='jiuzhou') {
      Object.keys(URLS).forEach(item => {
        urls[item] = URLS[item];
      })
    }else{
      Object.keys(URLS).forEach(item => {
        urls[item] = 'http://jiuzhou_admin.zhanyisc.com' + URLS[item];
      })
    }
}else{
    Object.keys(URLS).forEach(item=>{
        urls[item] = URLS[item];
    })
}

export default urls