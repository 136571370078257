<template>
  <!-- 多元整合 -->
    <div class="home-collec">
      <slot name="diy_title"></slot>
      <div class="home-collec-content">
        <div class="home-collec-content-item">
          <div class="collec-icons" :class="{'active':index==1}" @mouseenter="index=1">

            
            <div class="icons-img">
              <img src="../assets/imgs/collec1.png" alt="">
            </div>
            <img src="../assets/imgs/collec1.png"  alt="">
            <p>政府数字化</p>
          </div>
          <div class="collec-icons" :class="{'active':index==2}" @mouseenter="index=2">
            <div class="icons-img">
              <img src="../assets/imgs/collec2.png" alt="">
            </div>
            <img src="../assets/imgs/collec2.png" alt="">
            <p>军工数字化</p>
          </div>
          <div class="collec-icons" :class="{'active':index==3}" @mouseenter="index=3">
            <div class="icons-img">
              <img src="../assets/imgs/collec3.png" alt="">
            </div>
            <img src="../assets/imgs/collec3.png" alt="">
            <p>企业数字化</p>
          </div>
        </div>
      </div>
        
        
      
    </div>
</template>

<script>
export default {
  name:"collec",
  data(){
    return{
      show:true,
      index:1
    }
  }
}
</script>

<style lang="less" scoped>
// 多元整合
  .home-collec{
    width:100%;
    // height:10rem;
    margin-top:0.5rem;
    
    .home-collec-content{
      width:100%;
      height:355px;
      position: relative;
      display: flex;
      
      .home-collec-content-item{
        width:100%;
        height:200px;
        margin:auto;
        padding:0 24%;
        background-color:#F2F5FC;
        display: flex;
        justify-content: space-between;
        align-items:center;
        
        
        .collec-icons{
          text-align:center;
          position:relative;
          width: 33.3%;
          height:100%;
          cursor:pointer;
          
          
          &>img{
            
           position: absolute;
           bottom: 50%;
           left: 50%;
           transform: translate(-50%,50%);
          }
          
          .icons-img{
            width:150px;
            height:150px;
            background-color: #C7E0FE;
            border: 14px solid #fff;
            outline:1px solid #fff;
            transform: rotate(45deg) translate(-24%,45%);
            position: absolute;
            top: -50%;
            left:50%;
            display:none;
            img{
              transform: rotate(-45deg) translate(-70%,70%);
            }
          }
          p{
            position:absolute;
            bottom:17%;
            left: 50%;
            transform: translateX(-50%);
            font-weight: 700;
          }
          
          &:hover>img{
            display:none;
          }

          &:hover>.icons-img{
            display: block !important;
          }

          

        }

        .active{
          .icons-img{
            display:block !important;
          }
          &>img{
            display:none !important;
          }
        }
        

      }
    }

    
  }
</style>