<template>
  <!-- 多元整合 -->
    <div class="home-collec">
      <slot name="diy_title"></slot>
      <div class="collec-content">
        <div class="collec-content-item1">
          <div class="show">
            <img src="../assets/imgs/img6.png" alt="">
            <h3 class="font18">政府机关</h3>
            <p class="font14">促进政府系统数字化转型</p>
          </div>
          <div class="show-all">
            <div class="show-all-list hover-animate">
              <img src="../assets/imgs/img61.png" alt="">
              <p class="font14 font-bold">公安系统</p>
            </div>
            <div class="show-all-list hover-animate">
              <img src="../assets/imgs/img62.png" alt="">
              <p class="font14 font-bold">纪委监委</p>
            </div>
            <div class="show-all-list hover-animate">
              <img src="../assets/imgs/img63.jpg" alt="">
              <p class="font14 font-bold">特检所</p>
            </div>
            <div class="show-all-list hover-animate">
              <img src="../assets/imgs/img64.jpg" alt="">
              <p class="font14 font-bold">国资委</p>
            </div>
          </div>
        </div>
        <div class="collec-content-item1">
          <div class="show">
            <img src="../assets/imgs/img7.png" alt="">
            <h3 class="font18">军事院所</h3>
            <p class="font14">助力科技强军</p>
          </div>
          <div class="show-all" style="width:90%;height:80%;align-items:center;">
            <div class="show-all-list hover-animate">
              <img src="../assets/imgs/img65.jpg" alt="">
              <p class="font14 font-bold">心理训练</p>
            </div>
            <div class="show-all-list font14 font-bold hover-animate">
              <img src="../assets/imgs/img66.jpg" alt="">
              <p class="font14 font-bold">指挥控制</p>
            </div>
            <div class="show-all-list hover-animate">
              <img src="../assets/imgs/img67.jpg" alt="">
              <p class="font14 font-bold">特种数据分析</p>
            </div>
          </div>
        </div>
        <div class="collec-content-item1">
          <div class="show">
            <img src="../assets/imgs/img8.png" alt="">
            <h3 class="font18">国企民企</h3>
            <p class="font14">推进业务高效运行</p>
          </div>
          <div class="show-all">
            <div class="show-all-list hover-animate">
              <img src="../assets/imgs/img2.jpg" alt="">
              <p class="font14 font-bold">国有企业</p>
            </div>
            <div class="show-all-list hover-animate">
              <img src="../assets/imgs/img3.jpg" alt="">
              <p class="font14 font-bold">工业厂区</p>
            </div>
            <div class="show-all-list hover-animate">
              <img src="../assets/imgs/img4.jpg" alt="">
              <p class="font14 font-bold">产业园区</p>
            </div>
            <div class="show-all-list hover-animate">
              <img src="../assets/imgs/img5.jpg" alt="">
              <p class="font14 font-bold">商铺商场</p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name:"collec",
  data(){
    return{
      show:true
    }
  }
}
</script>

<style lang="less" scoped>
// 多元整合
  .home-collec{
    width:100%;
    height:10rem;
    margin-top:0.5rem;
    

    .collec-content{
      width:100%;
      // height:8.41rem;
      height:7.61rem;
      background-image: url("../assets/imgs/ximg1.jpg");
      background-size:100% 100%;
      display: flex;
      justify-content: space-between;
      align-content: center;

      .collec-content-item1{
        width:33.33333333333333333333%;
        height:100%;
        position: relative;
        p{
          font-size: 0.19rem;
        }
        &:hover{
          background-color:rgba(255, 255, 255, .2);
        }

        &::after{
          content:"";
          width:100%;
          height:100%;
          background-color:rgba(0,0,0,.2);
          background-size: cover;
          background-position: center;
          clip-path: inset(19.2rem,8.41rem);
          // filter: blur(0.02rem);
          filter: drop- shadow( 0px 20px 10px rgba(0, 0, 0, 0.5)); 
          position: absolute;
          left:0;
          top:0;
          display: black;
        }
        &:hover::after{
          display: none;
        }

        .show{
          z-index: 10000;
          color:#fff;
          position: absolute;
          text-align: center;
          left:50%;
          top:50%;
          transform: translate(-50%,-50%);
          transition: all 1s ease;
          font-size: 0.24rem;
          img{
            height:0.7rem;
          }
          
        }
        &:hover .show{
          top:24%;
          transition: all 1s ease;
        }

        .show-all{
          width:70%;
          height:4rem;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          text-align: center;
          position: absolute;
          // bottom: -1rem;
          bottom:0.8rem;
          left:50%;
          transform: translateX(-50%);
          color:#fff;
          // visibility: hidden;
          opacity: 0;

          img{
            width:1.78rem;
            height:1.02rem;
            border:0.05rem solid #fff;
          }
        }
        &:hover .show-all{
          // bottom:1rem;
          opacity: 1;
          // visibility: visible;
          transition: all 1s ease 50ms;
        }

      }
    }
  }
</style>