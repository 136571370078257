<template>
  <div id="app">
    <Nav />
    <router-view/>
    <CompanyInfo />
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue"
import CompanyInfo from '@/components/CompanyInfo.vue';
export default {
  name:'app',
  components:{
    Nav,CompanyInfo
  },
  

}
</script>

<style lang="less">
*{
  margin:0;
  padding:0;
  box-sizing:border-box;
}
#app {
  font-family: NotoSansSC,"思源黑体Bold",Avenir, Helvetica, Arial, sans-serif;
//  font-family: NotoSansSC,"思源黑体Bold";
 -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  font-size:0.16rem;
  min-width:8.7rem;
  padding-top:1rem;
}
a{
  text-decoration: none;
  color:inherit;
}

// @font-face {
//   font-family: NotoSansSC;
//   src: url("./assets/font/notosanssc.otf");
// }
// @font-face{
//   font-family: "思源黑体Bold";
//   src:url("./assets/font/notosansscbold.otf");
// }
.font-15{
  font-size: 0.15rem  ;
}

.font18{
  font-size:0.3rem;
  font-weight: 600;
}
.font32{
  font-size: 0.32rem;
  font-weight: 600;
}
.font35{
  font-size:0.35rem;
}
.flex-x{
  display:flex;
  justify-content: space-between;
  align-items: center;
}

</style>
