<template>
  <!-- 首页头部 -->
  <div class="home-header">
    <div class="home-header-content">
      <!-- <a  href="" class="home-header-logo"><img src="../assets/logo.png" alt="" /></a> -->
      <a
        class="logos"
        href="#/home"
        ><div class="home-header-logo"></div
      ></a>
      <ul class="home-header-list">
        <router-link to="/home"
          ><li
            :style="{
              'border-bottom':
                $route.path == '/home' ? '4px solid #2B68FF' : '',
              color: $route.path == '/home' ? '#2B68FF' : '',
            }">
            首页
          </li></router-link
        >
        <router-link to="/product">
          <li
            class="select"
            :style="{
              'border-bottom':
                $route.path == '/product' ? '4px solid #2B68FF' : '',
              color: $route.path == '/product' ? '#2B68FF' : '',
            }">
            <span
              class="select-list"
              :class="{ 'is-active': $route.path == '/product' }"></span>
            产品与服务
            <div
              class="product-list"
              style="transform: translateX(-35.7%)">
              <div class="product-child">
                <div class="product-child-l">
                  <h3 class="font18">产品与服务</h3>
                  <div
                    class="platforms1"
                    :class="{ checked: select1 == 1 }"
                    @mouseenter="select1 = 1">
                    大数据平台
                  </div>
                  <div
                    class="platforms1"
                    :class="{ checked: select1 == 2 }"
                    @mouseenter="select1 = 2">
                    Saas云服务
                  </div>
                  <div
                    class="platforms1"
                    :class="{ checked: select1 == 3 }"
                    @mouseenter="select1 = 3">
                    智能化设备
                  </div>
                </div>
                <div
                  class="product-child-r"
                  v-if="select1 == 1"
                  style="padding: 0.2rem 0.4rem 0.4rem">
                  <h4 class="default font16">大数据平台</h4>
                  <div class="r-item default">
                    <div class="flex-x">
                      <img
                        src="../assets/imgs/icon1.png"
                        alt="" />
                      <p>大数据智能分析平台</p>
                    </div>
                    <div class="flex-x">
                      <img
                        src="../assets/imgs/icon2.png"
                        alt="" />
                      <p>大数据可视化展现平台</p>
                    </div>
                    <div class="flex-x">
                      <img
                        src="../assets/imgs/icon3.png"
                        alt="" />
                      <p>大数据共享平台</p>
                    </div>
                    <div class="flex-x">
                      <img
                        src="../assets/imgs/icon4.png"
                        alt="" />
                      <p>大数据交换平台</p>
                    </div>
                  </div>
                </div>

                <div
                  class="product-child-r"
                  v-if="select1 == 2"
                  style="padding: 0.2rem 0.4rem 0.4rem">
                  <h4 class="default font16">Saas云服务</h4>
                  <div class="r-item default">
                    <div class="flex-x">
                      <img
                        src="../assets/imgs/icon7.png"
                        alt="" />
                      <p>基础设施云平台</p>
                    </div>
                    <div class="flex-x">
                      <img
                        src="../assets/imgs/icon8.png"
                        alt="" />
                      <p>云应用支撑平台</p>
                    </div>
                    <div class="flex-x">
                      <img
                        src="../assets/imgs/icon9.png"
                        alt="" />
                      <p>决策风控平台</p>
                    </div>
                    <div class="flex-x">
                      <img
                        src="../assets/imgs/icon10.png"
                        alt="" />
                      <p>统一门户平台</p>
                    </div>
                    <div class="flex-x">
                      <img
                        src="../assets/imgs/icon11.png"
                        alt="" />
                      <p>集中数字化监管平台</p>
                    </div>
                  </div>
                </div>

                <div
                  class="product-child-r"
                  v-if="select1 == 3"
                  style="padding: 0.2rem 0.4rem 0.4rem">
                  <h4 class="default font16">智能化设备</h4>
                  <div class="r-item default">
                    <div class="flex-x">
                      <img
                        src="../assets/imgs/icon1.png"
                        alt="" />
                      <p>智能化检测</p>
                    </div>
                    <div class="flex-x">
                      <img
                        src="../assets/imgs/icon2.png"
                        alt="" />
                      <p>军事智能化</p>
                    </div>
                    <div class="flex-x">
                      <img
                        src="../assets/imgs/icon3.png"
                        alt="" />
                      <p>心理健康管理</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </router-link>
        <!-- <router-link to=""> -->
        <li
          class="select"
          :style="{
            'border-bottom':
              $route.path == '/solution' ? '4px solid #2B68FF' : '',
            color: $route.path == '/solution' ? '#2B68FF' : '',
          }">
          <span
            class="select-list"
            :class="{ 'is-active': $route.path == '/solution' }"></span>
          <router-link :to="{ path: '/solution', query: { page: checkId } }"
            >解决方案</router-link
          >
          <div
            class="product-list"
            style="transform: translateX(-48%)">
            <div class="product-child">
              <div class="product-child-l cursor">
                <h3>解决方案</h3>
                <div
                  class="platforms1"
                  :class="{ checked: index == select2 }"
                  @mouseenter="select2 = index"
                  v-for="(item, index) in solutionNav"
                  :key="index">
                  {{ item.name }}
                </div>
              </div>

              <div
                class="product-child-r"
                v-if="select2 == 0">
                <template v-if="solutionNav[select2].children.length != 0">
                  <div
                    v-for="(i, j) in solutionNav[select2].children"
                    :key="j">
                    <h4
                      @click="hrefs(i.id)"
                      class="pointer light">
                      {{ i.name }}
                    </h4>
                    <div
                      class="r-item"
                      v-if="i.children != []">
                      <div
                        class="flex-x"
                        v-for="(k, z) in i.children"
                        :key="z"
                        style="height: 0.5rem">
                        <p
                          class="pointer light"
                          @click="hrefs(i.id)">
                          {{ k.productionName }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </div>

              <div
                class="product-child-r"
                v-if="select2 == 1">
                <template v-if="solutionNav[select2].children.length != 0">
                  <div
                    v-for="(i, j) in solutionNav[select2].children"
                    :key="j">
                    <h4
                      @click="hrefs(i.id)"
                      class="pointer light">
                      {{ i.name }}
                    </h4>
                    <div
                      class="r-item"
                      v-if="i.children != []">
                      <div
                        class="flex-x"
                        v-for="(k, z) in i.children"
                        :key="z"
                        style="height: 0.5rem">
                        <p
                          class="pointer light"
                          @click="hrefs(i.id)">
                          {{ k.productionName }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </div>

              <div
                class="product-child-r"
                v-if="select2 == 2">
                <div>
                  <h4>军工数字化</h4>
                  <div class="r-item">
                    <div class="flex-x">
                      <p>指挥控制系统</p>
                    </div>
                    <div class="flex-x">
                      <p>心理训练系统</p>
                    </div>
                    <div class="flex-x">
                      <p>特种数据分析系统</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- </router-link> -->
        <router-link to="/news"
          ><li
            :style="{
              'border-bottom':
                $route.path == ('/news' || '/newsinfo')
                  ? '4px solid #2B68FF'
                  : '',
              color: $route.path == ('/news' || '/newsinfo') ? '#2B68FF' : '',
            }">
            新闻中心
          </li></router-link
        >
        <li>
          <!-- <a
            href="https://zhdj.jezetek.cc/"
            target="_blank"
            >九洲党建</a
          > -->
          <a
            href=""
            target="_blank"
            >九洲党建</a
          >
        </li>
        <router-link to="/about">
          <li
            class="select"
            :style="{
              'border-bottom':
                $route.path == '/about' ? '4px solid #2B68FF' : '',
              color: $route.path == '/about' ? '#2B68FF' : '',
              position: 'relative',
            }">
            关于我们
            <div class="hover-list">
              <p class="hov"><router-link to="/about">了解我们</router-link></p>
              <p class="hov"><router-link to="/join">加入我们</router-link></p>
            </div>
          </li>
        </router-link>

        <router-link to="/support"
          ><li
            :style="{
              'border-bottom':
                $route.path == ('/support' || '/supportinfo')
                  ? '4px solid #2B68FF'
                  : '',
              color:
                $route.path == ('/support' || '/supportinfo') ? '#2B68FF' : '',
            }">
            合作支持
          </li></router-link
        >
      </ul>
    </div>
  </div>
</template>
<script>
import $request from "@/utils/request.js";
export default {
  name: "navs",
  created() {
    this.$api.get($request.solutionNav).then((res) => {
      this.solutionNav = res.data.data;
      this.checkId = this.solutionNav[0].children[0].id;
    });

    // this.select1 = 1;
    // this.select2 = 0;
  },
  data() {
    return {
      solutionNav: null,
      select1: 1,
      select2: 0,
      checkId: "",
    };
  },
  methods: {
    hrefs(name) {
      // console.log(name)
      // if(this.$route.path!='/solution'){
      //   this.$router.push({path:'/solution',query:{type:name}})
      // }else{
      //   localStorage.setItem('type',name);
      // }

      this.$router.push({ path: "/solution", query: { page: name } });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../utils/public.less");
.select {
  position: relative;
  font-size: 0;
  .select-list {
    display: inline-block;
    width: 0;
    height: 0;
    border-width: 0.06rem;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
    position: absolute;
    right: -0.06rem;
    top: 50%;
    transform: translateY(-20%);
  }

  .is-active {
    border-color: #0165f7 transparent transparent transparent;
  }

  &:hover .select-list {
    border-color: #0165f7 transparent transparent transparent;
  }
  &:hover .product-list {
    display: block;
  }

  &:hover .hover-list {
    display: flex;
  }

  .hover-list {
    position: absolute;
    bottom: -130px;
    left: 50%;
    transform: translateX(-50%);
    width: 132px;
    height: 126px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    line-height: 30px;
    display: none;
  }

  .product-list {
    display: none;
    position: absolute;
    width: 1200px;
    // height:5rem;
    height: auto;
    background-color: #fff;
    // bottom: -5.03rem;
    left: -50%;
    transform: translateX(-37%);
    z-index: 10000;
    color: #303030;
    box-shadow: 0 0 0.1rem 0.01rem rgba(0, 0, 0, 0.1);
    .product-child {
      display: flex;
    }
    .product-child-l {
      width: 3.3rem;
      // height:5rem;
      height: auto;
      padding: 0.2rem 0.4rem;
      box-shadow: 0 0 0.1rem 0.01rem rgba(0, 0, 0, 0.1);
      cursor: pointer;

      h3 {
        font-size: 18px;
      }

      .platforms1 {
        position: relative;
        font-size: 16px;
        height: 0.8rem;
        &:hover {
          color: #0165f7;
        }
        &::after {
          content: "";
          width: 0rem;
          height: 2px;
          background: #0165f7;
          position: absolute;
          bottom: 0;
          left: 0;
        }
        &:hover::after {
          content: "";
          width: 0.8rem;
          transition: all 1s ease;
        }
      }
      .checked {
        color: #0165f7;
        &::after {
          content: "";
          width: 0.8rem;
        }
      }
    }
    .product-child-r {
      padding: 0.6rem 0.4rem 0.4rem;
      width: 1000px;
      > div {
        height: 0.8rem;
        > h4 {
          font-size: 16px;
          height: 0.4rem;
          line-height: 0rem;
          padding-top: 0.1rem;
        }
      }

      .r-item {
        width: 10rem;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        margin-top: -20px;
        .flex-x {
          margin-right: 0.5rem;
          height: 0.5rem;
          line-height: 0.5rem;
          > img {
            margin-right: 0.1rem;
          }
          p {
            height: 100%;
          }
        }
      }
    }
  }
}

.hov {
  a {
    color: #303030;
    &:hover {
      color: #0165f7;
    }
  }
}
</style>
