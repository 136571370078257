import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SwiperView from '../views/SwiperView.vue';

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location){
  return originalPush.call(this,location).catch(err =>err)
}

const routes = [
  {
    path: '/',
    redirect:"/home"
  },
  {
    path:"/home",
    name:"home",
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path:'/support',
    name:'support',
    component: () => import( /* webpackChunkName: "support" */ '../views/SupportView.vue')
    
  },
  {
    path:"/supportinfo",
    name:"supportinfo",
    component: () => import( /* webpackChunkName: "supportinfo" */ '../views/SupportInfoView.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import( /* webpackChunkName: "news" */ '../views/NewsView.vue')
  },
  {
    path:"/newsinfo",
    name:"newsinfo",
    component: () => import( /* webpackChunkName: "newsinfo" */ '../views/NewsInfoView.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import( /* webpackChunkName: "product" */ '../views/ProductView.vue')
  },
  {
    path: '/solution',
    name: 'solution',
    props:true,
    component: () => import( /* webpackChunkName: "solution" */ '../views/SolutionView.vue')
  },
  {
    path:'/swipers',
    name:'swiper',
    component: () => import( /* webpackChunkName: "swiper" */ '../views/SwiperView.vue')
  },
  {
    path:'/join',
    name:'join',
    component: () => import( /* webpackChunkName: "swiper" */ '../views/JoinView.vue')
  }
  // {
  //   path:'/support1',
  //   name:'support1',
  //   component: ()=> import( /* webpackChunkName: "support1" */ '../components/Support1.vue')
  // },
  // {
  //   path:'/support2',
  //   name:'support2',
  //   component: ()=> import( /* webpackChunkName: "support1" */ '../components/Support2.vue')
  // },
  // {
  //   path:'/support3',
  //   name:'support3',
  //   component: ()=> import( /* webpackChunkName: "support1" */ '../components/Support3.vue')
  // }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior(to,from,saveTop){
    if(saveTop){
      return saveTop;
    }else{
      return {x:0,y:0}
    }
  },
  routes
})

export default router
