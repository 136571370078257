<template>
  <div class="home" ref="homes">
    <!-- 首页轮播 -->
    <div class="home-banner scrollitem" ref="banner">
       
        <el-carousel height="500px" :autoplay="false" @change="changeIndex">
          
            <el-carousel-item>
              <router-link to="/product">
              <div class="banner-t">
                <img src="../assets/imgs/banner_text.png" alt=""><br/>
                <img src="../assets/imgs/banner_btn.png" alt="">
              </div>
              <div id="lottie_box" class="animation" style="position:absolute;right:-0.5rem;width:860px;"></div>
            </router-link>
            </el-carousel-item>
          
          
            <el-carousel-item>
              <router-link to="/product">
                <div class="banner-t">
                  <img src="../assets/imgs/banner2_text.png" alt=""><br/>
                  <img src="../assets/imgs/banner_btn.png" alt="">
                </div>
                <img class="home-imgs " src="../assets/imgs/banner2.png" alt="" srcset="" style="right:0.5rem;">
              </router-link>
            </el-carousel-item>
          
          
            <el-carousel-item>
              <router-link to="/product">
                <div class="banner-t">
                  <img src="../assets/imgs/banner3_text.png" alt=""><br/>
                  <img src="../assets/imgs/banner_btn.png" alt="">
                </div>
                <img class="home-imgs" src="../assets/imgs/banner3.png" alt="" srcset="">
              </router-link>
            </el-carousel-item>
          
          
            <el-carousel-item>
              <router-link to="/product">
                <div class="banner-t">
                  <img src="../assets/imgs/banner4_text.png" alt=""><br/>
                  <img src="../assets/imgs/banner_btn.png" alt="">
                </div>
                <img class="home-imgs" src="../assets/imgs/banner4.png" alt="" srcset=""  >
              </router-link>
            </el-carousel-item>
        </el-carousel>
        <div class="home-banner-tab">
        <!-- <el-carousel height="150px" direction="vertical" ref="carousel" :initial-index="bannerIndex" :autoplay="false" indicator-position="none"> -->
          <!-- <el-carousel-item v-for="(item1,index1) in bannerTabs" :key="index1"> -->
            <template v-for="(i,k) in bannerTabs">
            <div class="home-banner-tab-item1"  >
              
                <div class="tab-icon" :key="k" >
                  <img :src="i.icon" alt="" srcset="" class="hover-animate">
                  <p>{{i.text}}</p>
                </div>
                <p style="font-size:16px;">{{i.value}}</p>
              
            </div>
            </template>
          <!-- </el-carousel-item> -->
        <!-- </el-carousel> -->
      </div>
    </div>

    <!-- 多元整合 -->
    <Collec class="scrollitem" >
      <template #diy_title>
        <div class="collec-title" >
        <h3 style="margin-bottom:0;">多元整合，满足各行业应用需求</h3>
        <p>针对客户的数智化转型需求，运用大数据、人工智能等技术，助力政府、军工、企业数字化转型</p>
      </div>
      </template>
    </Collec>
    

    <!-- 数字化转型 -->
    <div class="home-transform scrollitem" ref ="aaa">
      <div class="collec-title" style="margin:0 auto;">
        <h3>深耕各行业细分场景解决方案，赋能客户数字化转型</h3>
        
      </div>
      <div class="trans-content">
        <el-tabs tab-position="left">
          <el-tab-pane :label="item.za_title" v-for="(item,index) in solutionList" :key="index">
            <h2 class="trans-title font20" style="padding-top:0.1rem"><a href="javascript:;">{{item.za_subhead}}</a></h2>
            <div class="trans-describe">
              <div class="trans-des-position">
                <div class="desc">定位</div>
                <div class="desc-text" style="font-size:14px;line-height:2em;">{{item.za_intro}}</div>
              </div>
              <div class="trans-des-plat">
                <div class="desc">核心平台</div>
                <div class="plat-b">
                  <a :href="_i.link!=''?_i.link:'javascript:;'" v-for="(_i,_j) in item.za_content"><button>{{_i.name}}</button></a>
                </div>
              </div>
            </div>
            <div class="trans-imgs">
              <img :src="item.za_thumb" alt="" class="big-img" />
            </div>
            <div class="trans-b">
              <button class="btn1" @click="target(item)">了解更多</button>
              <router-link to="/support"><button class="btn2">立即咨询</button></router-link>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <!-- 大数据新时代 -->
    <div class="home-bigdata scrollitem">
      <div class="collec-title">
        <h3>“大数据+智能”新时代</h3>
        <p class="collec-describe">
          九洲软件核心技术架构，由混合云（大数据库）作为底层框架，算法引擎实现数据处理超大图计算模型，千万定点、亿级边运算时间&lt;1秒。运行四维时空视界算法，实现千万级数据秒级运算能力。掌握并运用云环境下laaS、PaaS层基础技术，攻克PaaS层业务流转、消息控制、数据一致性等5项关键技术。实现组件快速聚合、业务柔性可定制。
        </p>
      </div>
      <div class="home-bigdata-content">
        
        <img src="../assets/imgs/img11.jpg" alt=""> 
        <div class="trans-b">
        <router-link to="/about"><button class="btn1" >了解更多</button></router-link>
        <router-link to="/support"><button class="btn2">立即咨询</button></router-link>
      </div>
      </div>
    </div>

    <!-- 公司动态 -->
    <div class="home-dynamic scrollitem" >
        <div class="dynamic-cont">
          <h3>公司动态</h3>
          <el-tabs tab-position="left">
            <el-tab-pane label="新闻资讯">
              <div class="dynamic-item" v-for="(item,index) in companyNews" :key="item.za_id" @click="hrefs(item.za_id)">
                <a href="javascript:;"><img :src="item.za_thumb" alt=""></a>
                <p>{{item.za_create_time}}</p>
                <a href="javascript:;"><h3>{{item.za_title}}</h3></a>
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"><a href="https://zhdj.jezetek.cc/" target="_blank">党建活动</a></span>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"><router-link to="/join">社会招聘</router-link></span>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"><router-link to="/news">查看更多</router-link></span>
            </el-tab-pane>
          </el-tabs>
        </div> 
    </div>


  </div>
</template>

<script>
// @ is an alias to /src
import CompanyInfo from "@/components/CompanyInfo.vue";
import Nav from "@/components/Nav.vue";
import Collec from '@/components/CollecView.vue';
import Swipers from './SwiperView.vue';
import lottie from 'lottie-web';
import $request from '../utils/request.js';
// import * as anData from '/animate/data.json';
// import * as anData1 from '/animate/data3.json';
export default {
  name: 'HomeView',
  data(){
    return{
      bannerList: [
        { id: 1, src: require("../assets/imgs/banner1.png") }, 
        { id: 2, src: require("../assets/imgs/banner2.png") }, 
        { id: 3, src: require("../assets/imgs/banner3.png") }, 
        { id: 4, src: require("../assets/imgs/banner4.png") }],
      bannerTabs:[
        
          {id:1,icon:require("../assets/imgs/list1.png"),text:"数据融合",value:"为政军企提供数智化转型顶层设计"}, 
          {id: 2, icon: require("../assets/imgs/list2.png"), text: "数字实战",value:"助力数字解决方案驱动各领域创新发展"}, 
          {id: 4, icon: require("../assets/imgs/list4.png"), text: "行业大数据",value:"设计安全灵活的整套数据生态方案"}
         
      ],
      bannerIndex:0,
      show:true,
      shows:true,
      solutionList:null,
      newsList:null,
      companyNews:null,
      animation:null,
      animation1:null,
      scroll:"",
      activeStep:""
    }
  },
  components: {
    CompanyInfo,Nav,Collec,Swipers
  },
  created(){
    this.$api.get($request.solutionHome).then(res=>{
      console.log(res)
      this.solutionList = res.data.data;
    })

    this.$api.get($request.newsHome).then(res=>{
      this.newsList = res.data.data;
      this.companyNews = res.data.data;
    })

    // this.$api.get($request.newsHome).then(res=>{
    //   this.companyNews = res.data.data;
    // })
  },
  mounted(){
    lottie.loadAnimation({
      container:document.getElementById("lottie_box"),
      renderer:'svg',
      loop:true,
      autoplay:true,
      // animationData:anData.default,
      path:'/data.json'
    })

    lottie.loadAnimation({
      container:document.getElementById("lottie_box1"),
      renderer:'svg',
      loop:true,
      autoplay:true,
      // animationData:anData1.default,
      path:'/data3.json'
    })

    // document.getElementsByClassName("home").addEventListener('scroll',this.dataScroll,true);

    
  },
  methods:{
    changeIndex(i,j){
      // console.log(i,j)
      // this.bannerIndex = i;
      // this.$refs.carousel.setActiveItem(i);
      
    },
    hrefs(ids){
      this.$router.push({path:"/newsinfo",query:{id:ids,type:1}})
    },
    target(item){
      this.$router.push({path:'/solution',query:{page:item.za_id}})
    },
    
    
  }
}
</script>

<style lang="less">
@import url("../utils/public.less");
@import url("../utils/element.less");

.home{
  width:100%;
  

  .home-banner{
    width:100%;
    // height:9.51rem;
    // background-image: linear-gradient(-130deg, #0a4f98 0%,#1BEAAE 15%,#508AFF 50%,#fff);
    background-image:url("../assets/imgs/bg.png");
    background-size:100% 100%;

    .el-carousel__container{
      width:1300px;
      margin: auto;
      position:relative;
      button{
        i{
          color:#fff;
        }
      }

      .el-carousel__arrow--left{
        left:-1%;
      }
      .el-carousel__arrow--right{
        right:-1%;
      }
    }
  }

  .home-banner-tab{
    width:1200px;
    height:150px;
    margin:0 auto;
    display:flex;
    justify-content: space-around;
    align-items:center;
    .home-banner-tab-item1{
      
      display: flex;
      flex-direction: column;
      align-items: center;
      .tab-icon{
        display:flex;
        align-items:center;
        font-size: 18px;
        font-weight:700;
        margin-bottom:0.2rem;
        img{
          width: 40px;
          margin-right:0.1rem;
        }
      }
    }
  }



  

  

  






  // 首页轮播图样式展示
  .home-imgs{
    
    margin: 0 auto;
    position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    right:1rem;
  }

  .banner-t{
    width:1200px;
    height: 2rem;
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-70%);
    z-index:10000;
    color:#303030;
    &>h3{
      font-size:0.5rem;
      margin-bottom:0.2rem;
      
    }
    &>h4{
      font-size:0.3rem;
      margin-bottom:0.2rem;
    }
    &>p{
      font-size:0.24rem;
    }
    &>button{
      width:1.42rem;
      height:0.5rem;
      border-radius:0.3rem;
      background-color:rgb(44,104,255);
      color:#fff;
      font-size:0.22rem;
      line-height:0.5rem;
      text-align:center;
      border:none;
      margin-top:0.2rem;

    }
  }

  
  
}

</style>
